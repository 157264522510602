import React from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'

const BlogForm = ({ intl, status, message, onValidated }) => {
    let email, name
    const submit = (event) => {
        event.preventDefault()
        email &&
            name &&
            email.value.indexOf('@') > -1 &&
            onValidated({
                EMAIL: email.value,
                NAME: name.value,
            })
    }
    const placeholderName = intl.formatMessage({ id: 'subscribe.name' })
    const placeholderEmail = intl.formatMessage({ id: 'subscribe.email' })
    return (
        <div className="field mt-4 is-grouped is-grouped-multiline">
            <div className="control is-expanded">
                <form method="post" onSubmit={submit}>
                    {status === 'sending' && (
                        <div style={{ color: 'blue' }}>Loading...</div>
                    )}
                    {status === 'error' && (
                        <div
                            style={{ color: 'red' }}
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    {status === 'success' && (
                        <div
                            style={{ color: 'green' }}
                            dangerouslySetInnerHTML={{ __html: message }}
                        />
                    )}
                    <input
                        className="input has-input-style"
                        ref={(node) => (name = node)}
                        type="text"
                        placeholder={placeholderName}
                        required
                    />
                    <input
                        className="input has-input-style"
                        ref={(node) => (email = node)}
                        type="email"
                        placeholder={placeholderEmail}
                        required
                    />
                    <button
                        onClick={submit}
                        className="mt-3 button is-outlined is-primary has-text-weight-medium"
                    >
                        <FormattedMessage id="subscribe.submit" />
                    </button>
                </form>
            </div>
        </div>
    )
}

BlogForm.propTypes = {
    intl: intlShape.isRequired,
}

export default injectIntl(BlogForm)
