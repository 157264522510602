/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import BlogNav from '../components/BlogNav'
import SEO from '../components/SEO/SEO'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import BlogNewsletterForm from '../components/NewsletterForm/blog-subscribe'

class BlogIndexPage extends React.Component {
    render() {
        const data = this.props.data
        const location = this.props.location
        const jsonData = data.allArticlesJson.edges[0].node.articles
        return (
            <Layout data={data} jsonData={jsonData} location={location}>
                <SEO frontmatter={data.markdownRemark.frontmatter} />
                <div className="container">
                    <section className="is-blog-section">
                        <div className="container">
                            <BlogNav />
                            <div className="has-text-centered">
                                <div className="column is-8 is-offset-2">
                                    <h1 className="is-size-1 is-title-color">
                                        <FormattedMessage id="blog.roll.title" />
                                    </h1>
                                    <h2 className="is-size-5 mt-3 is-subtitle-color">
                                        <FormattedMessage id="blog.roll.subtitle" />
                                    </h2>
                                    <div className="column is-8 is-offset-2">
                                        <BlogNewsletterForm />
                                    </div>
                                </div>
                            </div>
                            <BlogRoll />
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

BlogIndexPage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default BlogIndexPage

export const pageQuery = graphql`
    query BlogIndex($id: String!) {
        site {
            siteMetadata {
                title
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                id
                date
                title
                description
                tags
                lang
            }
        }
    }
`
