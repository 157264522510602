import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Time from '../Time'
import { FormattedMessage } from 'react-intl'

const switchData = (data, langKey) => {
    // eslint-disable-next-line no-unused-vars
    var posts
    switch (langKey) {
        case '':
            return (posts = data.en)
        case 'ka':
            return (posts = data.ka)
        default:
            return ' '
    }
}

class BlogRoll extends React.Component {
    constructor(props) {
        super(props)
        this.state = { url: '' }
    }

    getUrl() {
        const urlChecker =
            window.location.pathname !== '/blog/'
                ? window.location.pathname
                : ''
        this.setState({ url: urlChecker })
    }

    componentDidMount() {
        this.getUrl()
    }

    toObject() {
        const dateForm = this.posts.map(
            ({ node: post }) => post.frontmatter.date
        )
        var rv = {}
        for (var i = 0; i < dateForm.length; ++i) rv[i] = dateForm[i]
        return rv
    }

    render() {
        const { data } = this.props
        const langKey = this.state.url.slice(1, 3)
        const { edges: posts } = switchData(data, langKey)
        return (
            <div className="columns is-multiline my-3">
                {posts &&
                    posts.map(({ node: post }) => (
                        <div className="column is-6" key={post.id}>
                            <div className="container is-mobile is-transition">
                                <article className="tile is-child white-ter">
                                    <Link to={post.fields.slug}>
                                        <figure className="is-3by2">
                                            <Img
                                                alt={post.frontmatter.imageAlt}
                                                fluid={
                                                    post.frontmatter.image
                                                        .childImageSharp.fluid
                                                }
                                            />
                                        </figure>
                                    </Link>
                                    <div className="has-article-content-padding">
                                        <div className="level is-mobile">
                                            <div className="level-left">
                                                <p className="is-size-7 is-blog-component-color tag is-dark">
                                                    {post.frontmatter.tags}
                                                </p>
                                            </div>
                                            <div className="level-right">
                                                <div className="is-size-7 is-blog-component-color tag is-dark">
                                                    <Time
                                                        pubdate
                                                        langKey={
                                                            post.fields.langKey
                                                        }
                                                        date={
                                                            post.frontmatter
                                                                .date
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <h2>
                                            <Link
                                                className="has-text-weight-normal is-blog-title-color is-size-5"
                                                to={post.fields.slug}
                                            >
                                                {post.frontmatter.title}
                                            </Link>
                                        </h2>
                                        <p className="mt-4">
                                            <Link
                                                className="content has-text-weight-normal is-blog-subtitle-color is-size-6"
                                                to={post.fields.slug}
                                            >
                                                {post.excerpt}
                                            </Link>
                                        </p>
                                        <div className="level is-mobile mt-5">
                                            <div className="level-left">
                                                <figure className="image is-32x32 is-margin-auto-zero">
                                                    <Img
                                                        className="is-image-radius-circle"
                                                        fluid={
                                                            post.frontmatter
                                                                .authorImage
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                    />
                                                </figure>
                                                <p className="is-size-7 ml-3 is-blog-component-color has-text-weight-medium">
                                                    {post.frontmatter.author}
                                                </p>
                                            </div>
                                            <div className="level-right">
                                                <p className="is-size-7 is-blog-component-color has-text-weight-medium">
                                                    {post.timeToRead}{' '}
                                                    <FormattedMessage id="blog.time.read" />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="has-text-right">
                                            <Link
                                                className="has-text-weight-medium  has-underline-from-center has-text-primary is-size-5"
                                                to={post.fields.slug}
                                            >
                                                <FormattedMessage id="blog.keep.reading" />
                                            </Link>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    ))}
            </div>
        )
    }
}

BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

// eslint-disable-next-line react/display-name
export default () => (
    <StaticQuery
        query={graphql`
            query BlogRollQuery {
                site {
                    siteMetadata {
                        title
                        languages {
                            langs
                            defaultLangKey
                        }
                    }
                }
                en: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "blog-post" }
                            lang: { regex: "/(en|any)/" }
                        }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 150)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                author
                                authorImage {
                                    childImageSharp {
                                        fluid(maxWidth: 32, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                title
                                templateKey
                                date
                                lang
                                tags
                                imageAlt
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            timeToRead
                        }
                    }
                }
                ka: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "blog-post" }
                            lang: { regex: "/(ka|any)/" }
                        }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 150)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                author
                                authorImage {
                                    childImageSharp {
                                        fluid(maxWidth: 32, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                title
                                templateKey
                                date
                                lang
                                tags
                                imageAlt
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <BlogRoll data={data} />}
    />
)
