import React, { Component } from 'react'
import SubscribeChimp from './components/subscribe-chimp'
import BlogForm from './components/blog-form'

class BlogNewsletterForm extends Component {
    render() {
        const url =
            'https://digital.us17.list-manage.com/subscribe/post?u=f1e6d05f59f9c095ac0993ca4&amp;id=5baf7c58ac'
        return (
            <div>
                <SubscribeChimp
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <BlogForm
                            status={status}
                            message={message}
                            onValidated={(formData) => subscribe(formData)}
                        />
                    )}
                />
            </div>
        )
    }
}

export default BlogNewsletterForm
